import React, { useState } from "react";
import { Icon, Uploader } from "@nutui/nutui-react";
import { useEffect } from "react";
const OSS = require("ali-oss");

const client = new OSS({
  // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
  region: "oss-cn-hangzhou",
  // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
  accessKeyId: "LTAI5tSeZMZryL6FJywmPrZP",
  accessKeySecret: "loYXYJJwpuGNqC6MCe8CGiTiaRmZpY",
  bucket: 'epicfish-media'
});
const App = (props) => {
  const uploadUrl = "http://epicfish-media.oss-cn-hangzhou.aliyuncs.com";
  const { defaultUrl, fileListCallBack, loading } = props;
  const [defaultFileList, setDefaultFileList] = useState(defaultUrl ? defaultUrl : []);
  const onDelete = (file) => {
    let newJson = [...defaultFileList];
    setDefaultFileList(newJson.filter(k => k !== file));
  };
  const onBeforeXhrUpload = async (file, option) => {
    let newdefaultFileList = [...defaultFileList];
    for (let item of file.slice(0, 4 - newdefaultFileList.length)) {
      let path = "";
      if (localStorage.getItem("userinfo") && JSON.parse(localStorage.getItem("userinfo"))?.Userid) {
        path = JSON.parse(localStorage.getItem("userinfo"))?.Userid
      }
      path = path + "/create/" + new Date().getTime() + "_" + item?.name
      try {
        const result = await client.put(path, item);
        newdefaultFileList.push(result?.url)
      } catch (e) {
        console.log(e);
      }
    }
    setDefaultFileList(newdefaultFileList)
    return true;
  }
  useEffect(() => {
    !loading && fileListCallBack(defaultFileList)
  }, [defaultFileList])
  return (
    <div className="cardBox" style={{ flexWrap: "wrap" }}>
      {
        defaultFileList && defaultFileList.map((item, index) => {
          return (
            <div key={index} style={{ padding: "20px", position: "relative" }} >
              <Icon name="close-little" style={{ position: "absolute", right: 0, top: 0 }} onClick={() => onDelete(item)}></Icon>
              <img src={item} style={{ width: "100px", height: "100px", display: "block", margin: "0 auto" }} />
            </div>
          )
        })
      }
      {
        defaultFileList.length < 4 && <Uploader
          url={uploadUrl}
          style={{ padding: "20px", }}
          multiple
          onBeforeUpload={onBeforeXhrUpload}
          maximum={4}
          accept="image/*"
        />
      }

    </div>
  );
};
export default App;
